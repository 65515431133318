import React, { useState } from 'react';
import { Camera, Star, Code, GitBranch } from 'lucide-react';
import { FaGithub, FaLinkedin, FaInstagram } from 'react-icons/fa';
import emailjs from 'emailjs-com';

const GameDeveloperPortfolio = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const [language, setLanguage] = useState('tr'); // Varsayılan dil Türkçe
  const [activeTab, setActiveTab] = useState('projects');

  // Çoklu dil desteği için çeviri nesnesi
  const translations = {
    tr: {
      title: 'Jr. Oyun Geliştirici',
      tabs: {
        projects: 'Projeler',
        about: 'Hakkımda', 
        skills: 'Yetenekler',
        contact: 'İletişim'
      },
      sections: 
      {
        projects: 
        {
          title: 'Projelerim',
          githubButton: 'Github\'da İncele',
          googlePlayButton: 'Google Play\'de İncele'
        },
        skills:
        {
            title: "Programlama Dilleri ve Yapıları",
            title2: "Oyun Geliştirme Araçları",
            oop: "OOP (Nesne Yönelimli Programlama)",
        },
        about: 
        {
          title: 'Hakkımda',
          description: 'Genç ve tutkulu bir oyun geliştiricisi olarak, yaratıcı ve kendimi geliştirmeye yönelik oyun projeleri geliştirmeye odaklanıyorum. Oyun tasarımı ve programlama konusunda sürekli kendimi geliştiriyor ve güncel teknolojik yapıları aktif olarak kullanmaya özen gösteriyorum.\n\nC#, C++, Git, OOP, Design Patterns, Solid Prensipleri, Unity Events, Addressables gibi yapıları ve dilleri biliyor ve geliştirdiğim oyunlarda aktif olarak kullanıyorum.',
          downloadCV: 'CV\'imi İndir'
        },
        contact:
        {
            title: "İletişim",
            nameHolder: "Adınız",
            mailHolder: "E-posta Adresiniz",
            messageHolder: "Mesajınız",
            sendButton: "Gönder",
            alertEmpty: "Lütfen tüm alanları doldurduğunuzdan emin olun.",
            alertSuccesfull: "Mesajınız başarıyla gönderildi!",
            alertFail: "Mesaj gönderilemedi: "
        },
        footer: 
        {
          copyright: "© 2024 Jr. Oyun Geliştirici. Tüm hakları saklıdır."
        }
      },
      
      certificate: {
        title: "Sertifikalarım",
        viewCertificate: "Sertifikayı Görüntüle",
        education: "Eğitim: ",
        1: {
          date: "22 Haziran 2023",
          title: "Unity ile Baştan Sona Platform Oyunu",
          issuer: "Udemy",
          skills: ["2D Oyunlar", "Algoritmalar", "Oyun Sektörü", "Oyun Geliştirme", "Oyun Programlama", "C#", "Unity"],
        },
        2: {
          date: "08 Ocak 2024",
          title: "Unity 101 - Visual Studio ve C#",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algoritmalar", "Oyun Sektörü", "Oyun Geliştirme", "Oyun Programlama", "C#", "Unity"],
        },
        3: {
          date: "11 Nisan 2024",
          title: "C++ Sıfırdan Komple Uygulamalı Nesneye Yönelik Programlama",
          issuer: "Udemy",
          skills: ["Algoritmalar", "C++", "OOP"],
        },
        4: {
          date: "27 Şubat 2024",
          title: "Unity 201 - Unity'e Giriş",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algoritmalar", "Oyun Sektörü", "Oyun Geliştirme", "Oyun Programlama", "C#", "Unity"],
        },
        5: {
          date: "15 Mart 2024",
          title: "Unity 301 - Uzay Savaşı Oyunu",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algoritmalar", "Oyun Sektörü", "Oyun Geliştirme", "Oyun Programlama", "C#", "Unity"],
        },
        6: {
          date: "29 Temmuz 2024",
          title: "Sourcetree ile Git ve GitHub Kullanımı",
          issuer: "Udemy",
          skills: ["Git", "Github", "Sourcetree"],
        },
        7: {
          date: "03 Ağustos 2024",
          title: "Unity 401 - Uzay Macerası Oyunu",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algoritmalar", "Oyun Sektörü", "Oyun Geliştirme", "Oyun Programlama", "C#", "Unity", "OOP"],
        }
      },
      
      games:
      {
        DeadRun:
        {
            description: "İlk Hypercasual Oyunum.",
        },
        
        Picker3D:
        {
            description: "Design Patterns, Unity Events, Asenkron Yapıları Öğrendiğim İlk Udemy Projem.",
        },
        
        BatFly:
        {
            description: "Picker3D Oyununda Öğrendiğim Yapıları Pekiştirmek İçin Yaptığım Oyunum.",
        }
      }
    },
    en: {
      title: 'Jr. Game Developer',
      tabs: 
      {
        projects: 'Projects',
        about: 'About', 
        skills: 'Skills',
        contact: 'Contact'
      },
      sections: 
      {
        projects: 
        {
          title: 'My Projects',
          githubButton: 'View on Github',
          googlePlayButton: 'View on Google Play'
        },
        skills:
        {
            title: "Programming Languages ​​and Structures",
            title2: "Game Development Tools",
            oop: "OOP (Object Oriented Programming)",
        },
        about: 
        {
          title: 'About Me',
          description: 'As a young and passionate game developer, I focus on developing creative and self-improvement-oriented game projects. I am constantly improving myself in game design and programming, and I make sure to actively use current technological frameworks.\n\nI am familiar with and actively use frameworks and languages such as C#, C++, Git, OOP, Design Patterns, Solid Principles, Unity Events, and Addressables in the games I develop.',
          downloadCV: 'Download CV'
        },
        contact: {
          title: "Contact",
          nameHolder: "Your Name",
          mailHolder: "Your Email Address",
          messageHolder: "Your Message",
          sendButton: "Send",
          alertEmpty: "Please make sure all fields are filled out.",
          alertSuccesfull: "Your message was successfully sent!",
          alertFail: "Message could not be sent: "
        },
        footer: 
        {
          copyright: "© 2024 Jr. Game Developer. All rights reserved."
        }
      },
      certificate: {
        title: "My certificates",
        viewCertificate: "View Certificate",
        education: "Education: ",
        1: {
          date: "June 22, 2023",
          title: "Platform Game from Scratch with Unity",
          issuer: "Udemy",
          skills: ["2D Games", "Algorithms", "Game Industry", "Game Development", "Game Programming", "C#", "Unity"],
        },
        2: {
          date: "January 8, 2024",
          title: "Unity 101 - Visual Studio and C#",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algorithms", "Game Industry", "Game Development", "Game Programming", "C#", "Unity"],
        },
        3: {
          date: "April 11, 2024",
          title: "C++ From Scratch Complete Practical Object-Oriented Programming",
          issuer: "Udemy",
          skills: ["Algorithms", "C++", "OOP"],
        },
        4: {
          date: "February 27, 2024",
          title: "Unity 201 - Introduction to Unity",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algorithms", "Game Industry", "Game Development", "Game Programming", "C#", "Unity"],
        },
        5: {
          date: "March 15, 2024",
          title: "Unity 301 - Space War Game",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algorithms", "Game Industry", "Game Development", "Game Programming", "C#", "Unity"],
        },
        6: {
          date: "July 29, 2024",
          title: "Using Git and GitHub with Sourcetree",
          issuer: "Udemy",
          skills: ["Git", "Github", "Sourcetree"],
        },
        7: {
          date: "August 3, 2024",
          title: "Unity 401 - Space Adventure Game",
          issuer: "Turkcell Geleceği Yazanlar",
          skills: ["Algorithms", "Game Industry", "Game Development", "Game Programming", "C#", "Unity", "OOP"],
        }
      },
      
      games: {
        DeadRun: {
          description: "My First Hypercasual Game."
        },
        
        Picker3D: {
          description: "My First Udemy Project where I learned Design Patterns, Unity Events, and Asynchronous Structures."
        },
        
        BatFly: {
          description: "A Game I Created to Reinforce the Concepts I Learned in Picker3D."
        }
      }
      
    }
  };

  const projects = [
    {
      name: "Dead Run",
      description: translations[language].games.DeadRun.description,
      technologies: ["Unity", "C#"],
      githubLink: "https://github.com/huseyinnatess/DeadRun",
      googlePlayLink: "https://play.google.com/store/apps/details?id=com.AtesGames.DeadRun&hl=tr",
      imageUrl: "https://raw.githubusercontent.com/huseyinnatess/DeadRun/master/Assets/Art/DeadRunLogo.png"
    },
    
    {
      name: "Bat Fly",
      description: "Picker3D Oyununda Öğrendiğim Yapıları Pekiştirmek İçin Yaptığım Oyunum.",
      technologies: ["Unity", "C#", "Design Patterns", "Unity Events"],
      githubLink:"https://github.com/huseyinnatess/BatFly",
      googlePlayLink: "https://play.google.com/store/apps/details?id=com.AtesGames.com.unity.template.mobile2D&pli=1",
      imageUrl: "https://raw.githubusercontent.com/huseyinnatess/BatFly/main/Assets/Art/Logo/Logo.png"
    },
    
    {
      name: "Picker 3D",
      description: translations[language].games.Picker3D.description,
      technologies: ["Unity", "C#", "Unity Events", "Design Patterns", "Solid"],
      githubLink: "https://github.com/huseyinnatess/Picker3D",
      imageUrl: "https://raw.githubusercontent.com/huseyinnatess/Picker3D/main/Assets/Art/Picker3DLogo.jpg"

    }  
  ];

  const CertificateCard = ({ date, title, issuer, skills, certificateUrl, imageUrl }) => (
    <div className="flex items-center p-6 space-x-6 bg-black/30 rounded-xl">
      <img src={imageUrl} alt={`${issuer} Logo`} className="object-contain w-16 h-16" />
      <div className="text-lg">
        <p>
          <span className="font-semibold text-gray-700">{date}</span><br />
          <span className="font-semibold">{translations[language].certificate.education}:</span> <span className="font-bold">{title}</span><br />
          <span className="text-sm font-semibold">{issuer}</span><br />
          <span className="font-semibold">{translations[language].tabs.skills}:</span> {skills.join(' · ')}
          <br />
          <a 
            href={certificateUrl} 
            className="inline-block px-3 py-1 text-white transition-colors duration-200 bg-blue-500 rounded-lg hover:bg-blue-600"
            target="_blank" 
            rel="noopener noreferrer"
          >
            {translations[language].certificate.viewCertificate}
          </a>
        </p>
      </div>
    </div>
  );

  const ContactForm = () => {
    const [formData, setFormData] = useState({
      name: "",
      email: "",
      message: ""
    });
  
    const handleSubmit = (e) => {
      e.preventDefault();

      if (!formData.name || !formData.email || !formData.message) {
        alert(translations[language].sections.contact.alertEmpty);
        return; // Eğer boş alan varsa, gönderimi engelle
      }
      
      // EmailJS ile e-posta gönder
      emailjs
        .sendForm(
          "service_2eb8khd", // EmailJS servis ID'nizi buraya ekleyin
          "template_71karij", // EmailJS şablon ID'nizi buraya ekleyin
          e.target,
          "q-nCXxesr63BCr349" // EmailJS kullanıcı ID'nizi buraya ekleyin
        )
        .then(
          (result) => {
            alert(translations[language].sections.contact.alertSuccesfull);
          },
          (error) => {
            alert(translations[language].sections.contact.alertFail + error.text);
          }
        );
    };
  
    return (
      <div className="max-w-md mx-auto">
        <h2 className="flex items-center justify-center mb-8 text-4xl font-bold text-center">
          <Camera className="mr-4" /> {translations[language].sections.contact.title}
        </h2>
        <form onSubmit={handleSubmit} className="p-8 space-y-4 bg-black/30 rounded-xl">
                <input 
                  type="text"
                  name="name"
                  placeholder={translations[language].sections.contact.nameHolder}
                  className="w-full p-3 text-white transition bg-gray-800 border-2 border-gray-700 rounded focus:border-blue-500"
                  value={formData.name}
                  onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                />
                <input 
                  type="email"
                  name="email"
                  placeholder={translations[language].sections.contact.mailHolder}
                  className="w-full p-3 text-white transition bg-gray-800 border-2 border-gray-700 rounded focus:border-blue-500"
                  value={formData.email}
                  onChange={(e) => setFormData({ ...formData, email: e.target.value })}
                />
                <textarea
                  name="message"
                  placeholder={translations[language].sections.contact.messageHolder}
                  value={formData.message}
                  rows={4}
                  className="w-full p-3 text-white transition bg-gray-800 border-2 border-gray-700 rounded focus:border-blue-500"
                  onChange={(e) => setFormData({ ...formData, message: e.target.value })}
                />
                <button 
                  type="submit"
                  className="w-full p-3 text-white transition bg-blue-600 rounded hover:bg-blue-700"
                >
                  {translations[language].sections.contact.sendButton}
                </button>
              </form>
      </div>
    );
  };

  return (
    <div className="min-h-screen text-white bg-gradient-to-br from-gray-900 to-blue-900">
      {/* Navigasyon */}
      <nav className="fixed top-0 left-0 right-0 z-50 shadow-lg bg-black/50 backdrop-blur-md">
        <div className="container flex items-center justify-between p-4 mx-auto">
          <h1 className="text-2xl font-bold text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600">
            {translations[language].title}
          </h1>
          
    {/* Dil Seçim Butonu */}
    <div className="flex items-center space-x-2">
            <button 
              onClick={() => setLanguage('tr')}
              className={`px-2 py-1 rounded ${language === 'tr' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'}`}
            >
              🇹🇷 TR
            </button>
            <button 
              onClick={() => setLanguage('en')}
              className={`px-2 py-1 rounded ${language === 'en' ? 'bg-blue-600 text-white' : 'hover:bg-gray-700'}`}
            >
              🇬🇧 EN
            </button>
          </div>

  
    {/* Mobil Hamburger Menü Butonu */}
    <div className="md:hidden">
      <button 
        onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
        className="text-white focus:outline-none"
      >
        {isMobileMenuOpen ? '✕' : '☰'}
      </button>
    </div>
    
    {/* Masaüstü Menüsü */}
    <div className="hidden space-x-4 md:flex">
      {['projects', 'about', 'skills', 'contact'].map(tab => (
        <button 
          key={tab}
          onClick={() => setActiveTab(tab)}
          className={`px-4 py-2 rounded-lg transition-all duration-300 ${
            activeTab === tab 
              ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white shadow-lg'
              : 'hover:bg-gray-700'
          }`}
        >
          {tab === 'projects' ? translations[language].tabs.projects : 
           tab === 'about' ? translations[language].tabs.about : 
           tab === 'skills' ? translations[language].tabs.skills : translations[language].tabs.contact}
        </button>
      ))}
    </div>
    
    {/* Mobil Açılır Menü */}
    {isMobileMenuOpen && (
      <div className="absolute left-0 right-0 top-full bg-black/90 md:hidden">
        {['projects', 'about', 'skills', 'contact'].map(tab => (
          <button 
            key={tab}
            onClick={() => {
              setActiveTab(tab);
              setIsMobileMenuOpen(false);
            }}
            className={`block w-full px-4 py-3 text-left transition-all duration-300 ${
              activeTab === tab 
                ? 'bg-gradient-to-r from-blue-600 to-purple-600 text-white'
                : 'hover:bg-gray-700'
            }`}
          >
            {tab === 'projects' ? translations[language].tabs.projects : 
           tab === 'about' ? translations[language].tabs.about : 
           tab === 'skills' ? translations[language].tabs.skills : translations[language].tabs.contact}
          </button>
        ))}
      </div>
    )}
  </div>
</nav>

      {/* Ana İçerik */}
      <div className="container px-4 pt-24 mx-auto">
        {activeTab === 'projects' && (
          <div>
            <h2 className="flex items-center justify-center mb-8 text-4xl font-bold text-center">
              <GitBranch className="mr-4" /> {translations[language].sections.projects.title}
            </h2>
            <div className="grid gap-8 md:grid-cols-3">
              {projects.map((project, index) => (
                <div 
                  key={index} 
                  className="overflow-hidden transition-transform shadow-2xl bg-black/30 rounded-xl hover:scale-105"
                >
                  <img 
                    src={project.imageUrl} 
                    alt={project.name} 
                    className="object-cover w-full h-48"
                  />
                  <div className="p-6">
                    <h3 className="mb-2 text-2xl font-semibold">{project.name}</h3>
                    <p className="mb-4 text-gray-300">{project.description}</p>
                    <div className="flex flex-wrap gap-2 mb-4">
                      {project.technologies.map((tech, techIndex) => (
                        <span 
                          key={techIndex} 
                          className="px-2 py-1 text-sm text-white bg-blue-600 rounded-full"
                        >
                          {tech}
                        </span>
                      ))}
                    </div>
                    <div className="space-y-1">
                  <a 
                    href={project.githubLink} 
                    target="_blank" 
                    rel="noopener noreferrer"
                    className="flex items-center justify-center px-4 py-2 text-white transition bg-green-600 rounded hover:bg-green-700"
                  >
                    <Code className="mr-2" /> {translations[language].sections.projects.githubButton}
                  </a>
                  {project.googlePlayLink && (
                    <a 
                      href={project.googlePlayLink} 
                      target="_blank" 
                      rel="noopener noreferrer"
                      className="flex items-center justify-center px-4 py-2 text-white transition bg-yellow-600 rounded hover:bg-blue-700"
                    >
                      {translations[language].sections.projects.googlePlayButton}
                    </a>
                  )}
                </div>

                  </div>
                </div>
              ))}
            </div>
          </div>
        )}
        
        {activeTab === 'about' && (
  <div className="max-w-2xl mx-auto text-left">
    <h2 className="mb-8 text-4xl font-bold text-transparent border-b-2 border-blue-500 bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">{translations[language].tabs.about.title}</h2>
    <div className="p-8 mb-8 bg-black/30 rounded-xl">
      <p className="text-lg leading-relaxed">
        {translations[language].sections.about.description}
      </p>
      <div className="container mx-auto mt-8 text-center">
      <a 
        href="https://drive.google.com/file/d/15JF-6RMYjBX1zwWTIZiVNB1M0DSemovT/view?usp=sharing" 
        download="HuseyinAtes_CV.pdf"
        className="px-6 py-3 text-white transition bg-blue-600 rounded-lg hover:bg-blue-700"
      >
        {translations[language].sections.about.downloadCV}
      </a>
</div>

    </div>

    <h2 className="mt-8 mb-8 text-4xl font-bold text-transparent border-b-2 border-blue-500 bg-clip-text bg-gradient-to-r from-blue-500 to-purple-500">{translations[language].certificate.title}</h2>

    <div className="space-y-8">
      {[
        {
          date: translations[language].certificate[1].date,
          title: translations[language].certificate[1].title,
          issuer: translations[language].certificate[1].issuer,
          skills: translations[language].certificate[1].skills,
          certificateUrl: "https://www.udemy.com/certificate/UC-0e664350-7bf4-4cf4-8d57-85923f2bbbc1/",
          imageUrl: "https://logowik.com/content/uploads/images/udemy-new-20212512.jpg"
        },
        {
          date: translations[language].certificate[2].date,
          title: translations[language].certificate[2].title,
          issuer: translations[language].certificate[2].issuer,
          skills: translations[language].certificate[2].skills,
          certificateUrl: "https://gelecegiyazanlar.turkcell.com.tr/certificate/dWlkMzE1NzA0Y2lkNTc1cWlkNjJlbmQ=",
          imageUrl: "https://raw.githubusercontent.com/huseyinnatess/readme.generator/main/image_src/GelecegiYazanlar.png"
        },
        {
          date: translations[language].certificate[3].date,
          title: translations[language].certificate[3].title,
          issuer: translations[language].certificate[3].issuer,
          skills: translations[language].certificate[3].skills,
          certificateUrl: "https://www.udemy.com/certificate/UC-bc29867d-1816-4226-80cb-451c03995ca9/",
          imageUrl: "https://logowik.com/content/uploads/images/udemy-new-20212512.jpg"
        },
        {
          date: translations[language].certificate[4].date,
          title: translations[language].certificate[4].title,
          issuer: translations[language].certificate[4].issuer,
          skills: translations[language].certificate[4].skills,
          certificateUrl: "https://gelecegiyazanlar.turkcell.com.tr/certificate/dWlkMzE1NzA0Y2lkNTc2cWlkNjNlbmQ=",
          imageUrl: "https://raw.githubusercontent.com/huseyinnatess/readme.generator/main/image_src/GelecegiYazanlar.png"
        },
        {
          date: translations[language].certificate[5].date,
          title: translations[language].certificate[5].title,
          issuer: translations[language].certificate[5].issuer,
          skills: translations[language].certificate[5].skills,
          certificateUrl: "https://gelecegiyazanlar.turkcell.com.tr/certificate/dWlkMzE1NzA0Y2lkNTc3cWlkNjRlbmQ=",
          imageUrl: "https://raw.githubusercontent.com/huseyinnatess/readme.generator/main/image_src/GelecegiYazanlar.png"
        },
        {
          date: translations[language].certificate[6].date,
          title: translations[language].certificate[6].title,
          issuer: translations[language].certificate[6].issuer,
          skills: translations[language].certificate[6].skills,
          certificateUrl: "https://www.udemy.com/certificate/UC-d1dea57c-2445-4295-9263-dbda173af1d9/",
          imageUrl: "https://logowik.com/content/uploads/images/udemy-new-20212512.jpg"
        },
        {
          date: translations[language].certificate[7].date,
          title: translations[language].certificate[7].title,
          issuer: translations[language].certificate[7].issuer,
          skills: translations[language].certificate[7].skills,
          certificateUrl: "https://gelecegiyazanlar.turkcell.com.tr/certificate/dWlkMzE1NzA0Y2lkNTc4cWlkNjVlbmQ=",
          imageUrl: "https://raw.githubusercontent.com/huseyinnatess/readme.generator/main/image_src/GelecegiYazanlar.png"
        }
      ].map((certificate, index) => (
        <CertificateCard 
          key={index}
          date={certificate.date}
          title={certificate.title}
          issuer={certificate.issuer}
          skills={certificate.skills}
          certificateUrl={certificate.certificateUrl}
          imageUrl={certificate.imageUrl}
        />
      ))}
    </div>
  </div>
)}

{activeTab === 'skills' && (
  <div className="max-w-4xl mx-auto">
    <h2 className="flex items-center justify-center mb-8 text-4xl font-bold text-center text-white-600">
      <Star className="mr-4" /> {translations[language].tabs.skills}
    </h2>
    <div className="grid gap-8 md:grid-cols-2">
      {/* Programming Languages and Concepts */}
      <div className="p-8 text-white bg-gradient-to-r from-purple-600 via-blue-600 to-green-600 rounded-xl">
        <h3 className="mb-6 text-3xl font-semibold text-center">{translations[language].sections.skills.title}</h3>
        <div className="flex flex-wrap justify-center gap-4">
          {['C#', 'C++', 'C', 'Solid', 'Design Patterns', 'Git', translations[language].sections.skills.oop].map((lang, index) => (
            <span 
              key={index} 
              className="px-4 py-2 text-white transition duration-300 ease-in-out transform bg-blue-700 rounded-full shadow-lg hover:scale-105"
            >
              {lang}
            </span>
          ))}
        </div>
      </div>

      {/* Game Development Tools */}
      <div className="p-8 text-white bg-gradient-to-r from-blue-500 via-teal-500 to-purple-500 rounded-xl">
        <h3 className="mb-6 text-3xl font-semibold text-center">{translations[language].sections.skills.title2}</h3>
        <div className="flex flex-wrap justify-center gap-4">
          {['Unity'].map((tool, index) => (
            <span 
              key={index} 
              className="px-4 py-2 text-white transition duration-300 ease-in-out transform bg-green-700 rounded-full shadow-lg hover:scale-105"
            >
              {tool}
            </span>
          ))}
        </div>
      </div>
    </div>
  </div>
)}


        {activeTab === 'contact' && (
         <div><ContactForm /></div>
        )}
      </div>

      {/* Footer */}
      <footer className="relative py-6 mt-12 overflow-hidden bg-black">
  {/* Gradyan Arka Plan Hareketi */}
  <div className="absolute inset-0 bg-gradient-to-r from-blue-700 via-indigo-700 to-purple-700 animate-gradient-x opacity-30"></div>
  
  <div className="container relative z-10 mx-auto text-center">
    <p className="text-lg font-light text-gray-300">{translations[language].sections.footer.copyright}</p>
    <div className="flex justify-center mt-8 space-x-12">
      {/* Github */}
      <a
        href="https://github.com/huseyinnatess"
        target="_blank"
        rel="noopener noreferrer"
        className="relative group"
      >
        <div className="relative flex items-center justify-center w-16 h-16 transition-transform duration-700 bg-gray-900 rounded-full shadow-2xl group-hover:scale-110">
          <FaGithub
            size={30}
            className="text-white transition-transform duration-500 group-hover:scale-125 group-hover:rotate-360"
          />
          {/* Yansıma Efekti */}
          <div className="absolute inset-0 w-full h-full transition-all duration-700 bg-green-500 rounded-full opacity-0 blur-xl group-hover:opacity-50"></div>
          {/* Dalga Hareketi */}
          <div className="absolute inset-0 border-2 border-green-500 rounded-full opacity-0 group-hover:animate-ping"></div>
        </div>
      </a>

      {/* LinkedIn */}
      <a
        href="https://linkedin.com/in/huseyin-ates-216a8631a"
        target="_blank"
        rel="noopener noreferrer"
        className="relative group"
      >
        <div className="relative flex items-center justify-center w-16 h-16 transition-transform duration-700 bg-gray-900 rounded-full shadow-2xl group-hover:scale-110">
          <FaLinkedin
            size={30}
            className="text-white transition-transform duration-500 group-hover:scale-125 group-hover:rotate-360"
          />
          {/* Yansıma Efekti */}
          <div className="absolute inset-0 w-full h-full transition-all duration-700 bg-blue-500 rounded-full opacity-0 blur-xl group-hover:opacity-50"></div>
          {/* Dalga Hareketi */}
          <div className="absolute inset-0 border-2 border-blue-500 rounded-full opacity-0 group-hover:animate-ping"></div>
        </div>
      </a>

      {/* Instagram */}
      <a
        href="https://www.instagram.com/ateeshuseyin"
        target="_blank"
        rel="noopener noreferrer"
        className="relative group"
      >
        <div className="relative flex items-center justify-center w-16 h-16 transition-transform duration-700 bg-gray-900 rounded-full shadow-2xl group-hover:scale-110">
          <FaInstagram
            size={30}
            className="text-white transition-transform duration-500 group-hover:scale-125 group-hover:rotate-360"
          />
          {/* Yansıma Efekti */}
          <div className="absolute inset-0 w-full h-full transition-all duration-700 bg-pink-500 rounded-full opacity-0 blur-xl group-hover:opacity-50"></div>
          {/* Dalga Hareketi */}
          <div className="absolute inset-0 border-2 border-pink-500 rounded-full opacity-0 group-hover:animate-ping"></div>
        </div>
      </a>
    </div>
  </div>
</footer>
    </div>
  );
};

export default GameDeveloperPortfolio;